var render = function render() {
  var _vm = this,
    _c = _vm._self._c
  return _c(
    "div",
    [
      _c("q-form", { ref: "editForm" }, [
        _c("div", { staticClass: "row" }, [
          _c(
            "div",
            {
              staticClass: "col-xs-12 col-sm-12 col-md-12 col-lg-12 col-xl-12",
            },
            [
              _c(
                "c-card",
                {
                  staticClass: "cardClassDetailForm",
                  attrs: { title: "LBLBASEINFO" },
                },
                [
                  _c(
                    "template",
                    { slot: "card-button" },
                    [
                      _c(
                        "q-btn-group",
                        { attrs: { outline: "" } },
                        [
                          _vm.editable && _vm.tbmData.tbmId
                            ? _c("c-qr-btn", {
                                attrs: {
                                  mobileUrl:
                                    "/sai/tbm/tbm?tbmId=" + _vm.tbmData.tbmId,
                                },
                              })
                            : _vm._e(),
                          _c("c-btn", {
                            directives: [
                              {
                                name: "show",
                                rawName: "v-show",
                                value: _vm.tbmData.tbmId,
                                expression: "tbmData.tbmId",
                              },
                            ],
                            attrs: { label: "출력", icon: "print" },
                            on: { btnClicked: _vm.printTbm },
                          }),
                          _c("c-btn", {
                            directives: [
                              {
                                name: "show",
                                rawName: "v-show",
                                value: _vm.editable && !_vm.tbmData.tbmId,
                                expression: "editable && !tbmData.tbmId",
                              },
                            ],
                            attrs: {
                              label: "타 TBM 불러오기",
                              icon: "save_alt",
                            },
                            on: { btnClicked: _vm.copyTbm },
                          }),
                          _c("c-btn", {
                            directives: [
                              {
                                name: "show",
                                rawName: "v-show",
                                value:
                                  _vm.editable &&
                                  _vm.tbmData.tbmId &&
                                  !_vm.disabled,
                                expression:
                                  "editable && tbmData.tbmId && !disabled",
                              },
                            ],
                            attrs: {
                              label: "LBLREMOVE",
                              editable: _vm.editable,
                              icon: "delete_forever",
                            },
                            on: { btnClicked: _vm.remove },
                          }),
                          _c("c-btn", {
                            directives: [
                              {
                                name: "show",
                                rawName: "v-show",
                                value: _vm.editable && !_vm.disabled,
                                expression: "editable && !disabled",
                              },
                            ],
                            attrs: {
                              url: _vm.saveUrl,
                              isSubmit: _vm.isSave,
                              param: _vm.tbmData,
                              mappingType: _vm.saveType,
                              label: "LBLSAVE",
                              icon: "save",
                            },
                            on: {
                              beforeAction: _vm.saveTbm,
                              btnCallback: _vm.saveCallback,
                            },
                          }),
                        ],
                        1
                      ),
                    ],
                    1
                  ),
                  _c("template", { slot: "card-detail" }, [
                    _c(
                      "div",
                      {
                        staticClass:
                          "col-xs-12 col-sm-12 col-md-2 col-lg-2 col-xl-2",
                      },
                      [
                        _c("c-text", {
                          attrs: {
                            editable: _vm.editable,
                            disabled: true,
                            label: "TBM번호",
                            name: "tbmNo",
                          },
                          model: {
                            value: _vm.tbmData.tbmNo,
                            callback: function ($$v) {
                              _vm.$set(_vm.tbmData, "tbmNo", $$v)
                            },
                            expression: "tbmData.tbmNo",
                          },
                        }),
                      ],
                      1
                    ),
                    _c(
                      "div",
                      {
                        staticClass:
                          "col-xs-12 col-sm-12 col-md-4 col-lg-4 col-xl-4",
                      },
                      [
                        _c("c-text", {
                          attrs: {
                            required: "",
                            editable: _vm.editable,
                            disabled: _vm.disabled,
                            label: "TBM명",
                            name: "tbmName",
                          },
                          model: {
                            value: _vm.tbmData.tbmName,
                            callback: function ($$v) {
                              _vm.$set(_vm.tbmData, "tbmName", $$v)
                            },
                            expression: "tbmData.tbmName",
                          },
                        }),
                      ],
                      1
                    ),
                    _c(
                      "div",
                      {
                        staticClass:
                          "col-xs-12 col-sm-12 col-md-2 col-lg-2 col-xl-2",
                      },
                      [
                        _c("c-work-permit", {
                          attrs: {
                            editable: _vm.editable,
                            disabled: _vm.disabled,
                            label: "작업허가번호",
                            name: "sopWorkPermitId",
                          },
                          on: {
                            dataChange: _vm.dataChange,
                            remove: _vm.removeWorkPermit,
                          },
                          model: {
                            value: _vm.tbmData.sopWorkPermitId,
                            callback: function ($$v) {
                              _vm.$set(_vm.tbmData, "sopWorkPermitId", $$v)
                            },
                            expression: "tbmData.sopWorkPermitId",
                          },
                        }),
                      ],
                      1
                    ),
                    _c(
                      "div",
                      {
                        staticClass:
                          "col-xs-12 col-sm-12 col-md-2 col-lg-2 col-xl-2",
                      },
                      [
                        _c("c-vendor", {
                          attrs: {
                            editable: _vm.editable,
                            disabled: _vm.disabled,
                            haveProcess: true,
                            selfFlag: false,
                            label: "업체",
                            name: "vendorCd",
                          },
                          model: {
                            value: _vm.tbmData.vendorCd,
                            callback: function ($$v) {
                              _vm.$set(_vm.tbmData, "vendorCd", $$v)
                            },
                            expression: "tbmData.vendorCd",
                          },
                        }),
                      ],
                      1
                    ),
                    _c(
                      "div",
                      {
                        staticClass:
                          "col-xs-12 col-sm-12 col-md-2 col-lg-2 col-xl-2",
                      },
                      [
                        _c("c-dept", {
                          attrs: {
                            editable: _vm.editable,
                            disabled: _vm.disabled,
                            label: "부서",
                            name: "deptCd",
                          },
                          model: {
                            value: _vm.tbmData.deptCd,
                            callback: function ($$v) {
                              _vm.$set(_vm.tbmData, "deptCd", $$v)
                            },
                            expression: "tbmData.deptCd",
                          },
                        }),
                      ],
                      1
                    ),
                    _c(
                      "div",
                      {
                        staticClass:
                          "col-xs-12 col-sm-12 col-md-2 col-lg-2 col-xl-2",
                      },
                      [
                        _c("c-datepicker", {
                          attrs: {
                            required: "",
                            editable: _vm.editable,
                            disabled: _vm.disabled,
                            label: "작업일",
                            type: "date",
                            name: "tbmWorkDate",
                          },
                          model: {
                            value: _vm.tbmData.tbmWorkDate,
                            callback: function ($$v) {
                              _vm.$set(_vm.tbmData, "tbmWorkDate", $$v)
                            },
                            expression: "tbmData.tbmWorkDate",
                          },
                        }),
                      ],
                      1
                    ),
                    _c(
                      "div",
                      {
                        staticClass:
                          "col-xs-12 col-sm-12 col-md-2 col-lg-2 col-xl-2",
                      },
                      [
                        _c("c-process", {
                          attrs: {
                            editable: _vm.editable,
                            disabled: _vm.disabled,
                            label: "LBLPROCESS",
                            name: "processCd",
                          },
                          model: {
                            value: _vm.tbmData.processCd,
                            callback: function ($$v) {
                              _vm.$set(_vm.tbmData, "processCd", $$v)
                            },
                            expression: "tbmData.processCd",
                          },
                        }),
                      ],
                      1
                    ),
                    _c(
                      "div",
                      {
                        staticClass:
                          "col-xs-12 col-sm-12 col-md-4 col-lg-4 col-xl-4",
                      },
                      [
                        _c("c-text", {
                          attrs: {
                            editable: _vm.editable,
                            disabled: _vm.disabled,
                            label: "LBLJOB",
                            name: "sopName",
                          },
                          model: {
                            value: _vm.tbmData.sopName,
                            callback: function ($$v) {
                              _vm.$set(_vm.tbmData, "sopName", $$v)
                            },
                            expression: "tbmData.sopName",
                          },
                        }),
                      ],
                      1
                    ),
                    _c(
                      "div",
                      {
                        staticClass:
                          "col-xs-12 col-sm-12 col-md-2 col-lg-2 col-xl-2",
                      },
                      [
                        _c("c-field", {
                          attrs: {
                            editable: _vm.editable,
                            disabled: _vm.disabled,
                            label: "작업책임자",
                            name: "tbmWorkResponsibleId",
                          },
                          model: {
                            value: _vm.tbmData.tbmWorkResponsibleId,
                            callback: function ($$v) {
                              _vm.$set(_vm.tbmData, "tbmWorkResponsibleId", $$v)
                            },
                            expression: "tbmData.tbmWorkResponsibleId",
                          },
                        }),
                      ],
                      1
                    ),
                    _c(
                      "div",
                      {
                        staticClass:
                          "col-xs-12 col-sm-12 col-md-2 col-lg-2 col-xl-2",
                      },
                      [
                        _c("c-plant", {
                          attrs: {
                            required: "",
                            editable: _vm.editable,
                            disabled: _vm.disabled,
                            type: "edit",
                            name: "plantCd",
                          },
                          model: {
                            value: _vm.tbmData.plantCd,
                            callback: function ($$v) {
                              _vm.$set(_vm.tbmData, "plantCd", $$v)
                            },
                            expression: "tbmData.plantCd",
                          },
                        }),
                      ],
                      1
                    ),
                    _c(
                      "div",
                      {
                        staticClass:
                          "col-xs-12 col-sm-12 col-md-4 col-lg-4 col-xl-4",
                      },
                      [
                        _c("c-checkbox", {
                          attrs: {
                            disabled: _vm.disabled,
                            editable: _vm.editable,
                            isArray: false,
                            codeGroupCd: "TBM_PRE_INSPECTION_CD",
                            itemText: "codeName",
                            itemValue: "code",
                            label: "사전점검",
                            name: "preInspections",
                          },
                          model: {
                            value: _vm.tbmData.preInspections,
                            callback: function ($$v) {
                              _vm.$set(_vm.tbmData, "preInspections", $$v)
                            },
                            expression: "tbmData.preInspections",
                          },
                        }),
                      ],
                      1
                    ),
                    _c(
                      "div",
                      {
                        staticClass:
                          "col-xs-12 col-sm-12 col-md-4 col-lg-4 col-xl-4",
                      },
                      [
                        _c("c-textarea", {
                          attrs: {
                            editable: _vm.editable,
                            disabled: _vm.disabled,
                            label: "작업내용",
                            rows: 2,
                            name: "tbmWorkContents",
                          },
                          model: {
                            value: _vm.tbmData.tbmWorkContents,
                            callback: function ($$v) {
                              _vm.$set(_vm.tbmData, "tbmWorkContents", $$v)
                            },
                            expression: "tbmData.tbmWorkContents",
                          },
                        }),
                      ],
                      1
                    ),
                    _c(
                      "div",
                      {
                        staticClass:
                          "col-xs-12 col-sm-12 col-md-4 col-lg-4 col-xl-4",
                      },
                      [
                        _c("c-textarea", {
                          attrs: {
                            editable: _vm.editable,
                            disabled: _vm.disabled,
                            label: "특이사항",
                            rows: 2,
                            name: "significant",
                          },
                          model: {
                            value: _vm.tbmData.significant,
                            callback: function ($$v) {
                              _vm.$set(_vm.tbmData, "significant", $$v)
                            },
                            expression: "tbmData.significant",
                          },
                        }),
                      ],
                      1
                    ),
                  ]),
                ],
                2
              ),
            ],
            1
          ),
          _c(
            "div",
            { staticClass: "col-xs-12 col-sm-12 col-md-8 col-lg-8 col-xl-8" },
            [
              _c(
                "c-table",
                {
                  ref: "riskTable",
                  attrs: {
                    title: "위험요인 목록",
                    columns: _vm.riskGrid.columns,
                    gridHeight: _vm.riskGrid.height,
                    data: _vm.tbmData.tbmRiskHazardList,
                    filtering: false,
                    columnSetting: false,
                    usePaging: false,
                    noDataLabel: "위험요인을 추가하세요.",
                    hideBottom: true,
                    editable: _vm.editable && !_vm.disabled,
                    rowKey: "tbmRiskId",
                    selection: "multiple",
                  },
                },
                [
                  _c(
                    "template",
                    { slot: "table-button" },
                    [
                      _c(
                        "q-btn-group",
                        { attrs: { outline: "" } },
                        [
                          _vm.editable && !_vm.disabled
                            ? _c("c-btn", {
                                attrs: {
                                  showLoading: false,
                                  label: "LBLADDDIRECTLY",
                                  icon: "add",
                                },
                                on: { btnClicked: _vm.addRisk },
                              })
                            : _vm._e(),
                          _vm.editable &&
                          !_vm.disabled &&
                          _vm.tbmData.tbmRiskHazardList.length > 0
                            ? _c("c-btn", {
                                attrs: {
                                  showLoading: false,
                                  label: "LBLEXCEPT",
                                  icon: "remove",
                                },
                                on: { btnClicked: _vm.removeRisk },
                              })
                            : _vm._e(),
                        ],
                        1
                      ),
                    ],
                    1
                  ),
                ],
                2
              ),
            ],
            1
          ),
          _c(
            "div",
            { staticClass: "col-xs-12 col-sm-12 col-md-4 col-lg-4 col-xl-4" },
            [
              _c(
                "c-table",
                {
                  ref: "equipTable",
                  attrs: {
                    title: "장비현황 목록",
                    columns: _vm.equipGrid.columns,
                    gridHeight: _vm.equipGrid.height,
                    data: _vm.tbmData.tbmEquipList,
                    filtering: false,
                    columnSetting: false,
                    usePaging: false,
                    noDataLabel: "장비현황을 추가하세요.",
                    hideBottom: true,
                    editable: _vm.editable && !_vm.disabled,
                    rowKey: "tbmEquipId",
                    selection: "multiple",
                  },
                },
                [
                  _c(
                    "template",
                    { slot: "table-button" },
                    [
                      _c(
                        "q-btn-group",
                        { attrs: { outline: "" } },
                        [
                          _vm.editable && !_vm.disabled
                            ? _c("c-btn", {
                                attrs: {
                                  showLoading: false,
                                  label: "LBLADDDIRECTLY",
                                  icon: "add",
                                },
                                on: { btnClicked: _vm.addEquip },
                              })
                            : _vm._e(),
                          _vm.editable &&
                          !_vm.disabled &&
                          _vm.tbmData.tbmEquipList.length > 0
                            ? _c("c-btn", {
                                attrs: {
                                  showLoading: false,
                                  label: "LBLEXCEPT",
                                  icon: "remove",
                                },
                                on: { btnClicked: _vm.removeEquip },
                              })
                            : _vm._e(),
                        ],
                        1
                      ),
                    ],
                    1
                  ),
                ],
                2
              ),
            ],
            1
          ),
          _c(
            "div",
            { staticClass: "col-xs-12 col-sm-12 col-md-8 col-lg-8 col-xl-8" },
            [
              _c(
                "c-table",
                {
                  ref: "attendeeTable",
                  attrs: {
                    title: "참석자 및 건강상태 목록",
                    columns: _vm.attendeeGrid.columns,
                    gridHeight: _vm.attendeeGrid.height,
                    data: _vm.tbmData.tbmAttendeeList,
                    filtering: false,
                    columnSetting: false,
                    usePaging: false,
                    noDataLabel: "참석자가 없습니다.",
                    hideBottom: true,
                    editable: _vm.editable && !_vm.disabled,
                    selection: "multiple",
                    rowKey: "tbmAttendeeId",
                  },
                  scopedSlots: _vm._u([
                    {
                      key: "customArea",
                      fn: function ({ props, col }) {
                        return [
                          col.name === "click" &&
                          props.row["tbmDirectFlag"] !== "Y"
                            ? [
                                _c(
                                  "q-btn",
                                  {
                                    attrs: {
                                      round: "",
                                      unelevated: "",
                                      size: "10px",
                                      color: "amber",
                                      icon: "mode",
                                    },
                                    on: {
                                      click: function ($event) {
                                        $event.stopPropagation()
                                        return (() => {
                                          ;(_vm.rowIndex = props.rowIndex),
                                            (_vm.electronSignature =
                                              props.row.electronSignature)
                                        }).apply(null, arguments)
                                      },
                                    },
                                  },
                                  [
                                    _c(
                                      "q-popup-proxy",
                                      {
                                        ref: "proxy_" + props.rowIndex,
                                        attrs: { breakpoint: 400 },
                                      },
                                      [
                                        _c(col.component, {
                                          tag: "component",
                                          attrs: {
                                            outFlag: true,
                                            popupParam: props.row,
                                            data: _vm.tbmData,
                                            rowIndex: props.rowIndex,
                                          },
                                          on: { callback: _vm.callback },
                                        }),
                                      ],
                                      1
                                    ),
                                  ],
                                  1
                                ),
                              ]
                            : _vm._e(),
                        ]
                      },
                    },
                  ]),
                },
                [
                  _c(
                    "template",
                    { slot: "table-button" },
                    [
                      _c(
                        "q-btn-group",
                        { attrs: { outline: "" } },
                        [
                          _vm.editable && !_vm.disabled
                            ? _c("c-btn", {
                                attrs: {
                                  showLoading: false,
                                  label: "추가(업체 근무자)",
                                  icon: "add",
                                },
                                on: { btnClicked: _vm.addAttendee1 },
                              })
                            : _vm._e(),
                          _vm.editable && !_vm.disabled
                            ? _c("c-btn", {
                                attrs: {
                                  showLoading: false,
                                  label: "추가(현업)",
                                  icon: "add",
                                },
                                on: { btnClicked: _vm.addAttendee2 },
                              })
                            : _vm._e(),
                          _vm.editable &&
                          !_vm.disabled &&
                          _vm.tbmData.tbmAttendeeList.length > 0
                            ? _c("c-btn", {
                                attrs: {
                                  showLoading: false,
                                  label: "LBLEXCEPT",
                                  icon: "remove",
                                },
                                on: { btnClicked: _vm.removeAttendee },
                              })
                            : _vm._e(),
                        ],
                        1
                      ),
                    ],
                    1
                  ),
                ],
                2
              ),
            ],
            1
          ),
          _c(
            "div",
            { staticClass: "col-4" },
            [
              _c("c-upload", {
                staticStyle: { "padding-top": "20px" },
                attrs: {
                  attachInfo: _vm.attachInfo,
                  editable: _vm.editable && !_vm.disabled,
                  label: "교육자료",
                },
              }),
            ],
            1
          ),
        ]),
      ]),
      _c("c-dialog", { attrs: { param: _vm.popupOptions } }),
    ],
    1
  )
}
var staticRenderFns = []
render._withStripped = true

export { render, staticRenderFns }